import React, { useState } from 'react';
import axios from 'axios';
import './formStyles.css'; // Import the new form styles
import LoadingDialog from './LoadingDialog';
import CustomAlert from './CustomAlert';
import { useNavigate } from 'react-router-dom';
import BACKEND_URL from './config'; // Import the BACKEND_URL from the config file

const SpotQuotesForm = () => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    const initialFormState = {
        referenceNumber: '',
        shipper: '',
        shipFromAddress: '',
        shipFromCity: '',
        shipFromState: '',
        shipFromZip: '',
        consignee: '',
        consigneeAddress: '',
        consigneeCity: '',
        consigneeState: '',
        consigneeZip: '',
        pickupDate: today, // Set default pickup date to today
        weight: '',
        pallets: '',
        modeType: 'FTL', // Default mode type
        freightClass: '' // Add class field
    };

    const [formData, setFormData] = useState(initialFormState);
    const [quoteResponse, setQuoteResponse] = useState(null); // State for quote response
    const [isLoading, setIsLoading] = useState(false); // State for loading dialog during form submission
    const [isBooking, setIsBooking] = useState(false); // State for loading dialog during booking
    const [alert, setAlert] = useState({ show: false, title: '', message: '' }); // State for custom alert
    const navigate = useNavigate();
    const usStates = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ];

    const classOptions = [
        "", "50", "55", "60", "65", "70", "77.5", "85", "92.5", "100", "110", "125", "150", "175", "200", "250", "300", "400", "500"
    ];

    // Date validation function
    const isValidDate = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        if (!formData.shipFromAddress) {
            setAlert({ show: true, title: 'Error', message: 'Ship From Address cannot be blank' });
            return false;
        }
        if (!formData.consigneeAddress) {
            setAlert({ show: true, title: 'Error', message: 'Consignee Address cannot be blank' });
            return false;
        }
        if (!/^\d{5}$/.test(formData.shipFromZip)) {
            setAlert({ show: true, title: 'Error', message: 'Ship From Zip must be at least 5 numerical digits' });
            return false;
        }
        if (!/^\d{5}$/.test(formData.consigneeZip)) {
            setAlert({ show: true, title: 'Error', message: 'Consignee Zip must be at least 5 numerical digits' });
            return false;
        }
        if (!isValidDate(formData.pickupDate)) {
            setAlert({ show: true, title: 'Error', message: 'Invalid Pickup Date.' });
            return false;
        }
        if (new Date(formData.pickupDate) <= new Date(today)) {
            setAlert({ show: true, title: 'Error', message: 'Pickup Date needs to be later than today' });
            return false;
        }
        if (formData.weight <= 0) {
            setAlert({ show: true, title: 'Error', message: 'Weight must be greater than 0' });
            return false;
        }
        if (formData.pallets <= 0) {
            setAlert({ show: true, title: 'Error', message: 'Pallets must be greater than 0' });
            return false;
        }

        if (formData.modeType === 'LTL' && !formData.freightClass) {
            setAlert({ show: true, title: 'Error', message: 'Freight Class is required when Mode Type is LTL' });
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsLoading(true); // Show loading dialog
        try {
            const response = await axios.post(`${BACKEND_URL}/api/spot-quotes`, formData, { withCredentials: true });
            setQuoteResponse(response.data); // Set quote response data
            console.log(response.data)
            setAlert({ show: true, title: 'Success', message: 'Form submitted successfully!' });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Redirect to login page if 401 error is encountered
                navigate('/');
            } else {
                console.error('Error submitting form:', error);
                setAlert({ show: true, title: 'Error', message: 'There was an error submitting the form.' });
            }
        } finally {
            setIsLoading(false); // Hide loading dialog
        }
    };

    const handleBookShipment = async (quote, event) => {
        event.stopPropagation();
        event.preventDefault();
        setIsBooking(true); // Show loading dialog for booking
        try {
            // Combine formData and quote into a single object
            const combinedData = {
                formData, // Includes form fields such as shipper, referenceNumber, etc.
                quote    // Includes the quote data received from the response
            };

            // Send the combined data to the backend
            await axios.post(`${BACKEND_URL}/api/book-shipment-spot-quotes`, combinedData, { withCredentials: true });
            
            setAlert({ show: true, title: 'Success', message: 'Shipment booked successfully!' });
            //setFormData(initialFormState); // Reset form fields
            setQuoteResponse(null); // Clear quote summaries table
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Redirect to login page if 401 error is encountered
                navigate('/');
            } else {
                console.error('Error booking shipment:', error);
                setAlert({ show: true, title: 'Error', message: 'There was an error booking the shipment.' });
            }
        } finally {
            setIsBooking(false); // Hide loading dialog for booking
        }
    };

    const handleClear = () => {
        setFormData(initialFormState); // Reset form fields
        setQuoteResponse(null); // Clear quote summaries table
    };

    const handleCloseAlert = () => {
        setAlert({ show: false, title: '', message: '' });
    };

    return (
        <div className="main-content-spot-quotes">
            <h2>Spot Quotes</h2>
            <div className="form-container">
                <form onSubmit={handleSubmit} className="spot-quotes-form">
                    <div className="form-column">
                        <div className="form-group">
                            <label>Mode Type</label>
                            <div className="radio-group-horizontal">
                                <div>
                                    <input
                                        type="radio"
                                        id="ftl"
                                        name="modeType"
                                        value="FTL"
                                        checked={formData.modeType === 'FTL'}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="ftl">FTL</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="ltl"
                                        name="modeType"
                                        value="LTL"
                                        checked={formData.modeType === 'LTL'}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="ltl">LTL</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="interModal"
                                        name="modeType"
                                        value="InterModal"
                                        checked={formData.modeType === 'InterModal'}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="interModal">InterModal</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Reference Number</label>
                            <input type="text" name="referenceNumber" value={formData.referenceNumber} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Shipper</label>
                            <input type="text" name="shipper" value={formData.shipper} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Ship From Address</label>
                            <input type="text" name="shipFromAddress" value={formData.shipFromAddress} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Ship From City</label>
                            <input type="text" name="shipFromCity" value={formData.shipFromCity} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Ship From State</label>
                            <select name="shipFromState" value={formData.shipFromState} onChange={handleChange} required>
                                <option value="">Select a state</option>
                                {usStates.map(state => (
                                    <option key={state} value={state}>{state}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Ship From Zip</label>
                            <input type="text" name="shipFromZip" value={formData.shipFromZip} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Freight Class</label>
                            <select name="freightClass" value={formData.freightClass} onChange={handleChange}>
                                {classOptions.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="form-column consignee-group">
                        <div className="form-group">
                            <label>Consignee</label>
                            <input type="text" name="consignee" value={formData.consignee} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Consignee Address</label>
                            <input type="text" name="consigneeAddress" value={formData.consigneeAddress} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Consignee City</label>
                            <input type="text" name="consigneeCity" value={formData.consigneeCity} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Consignee State</label>
                            <select name="consigneeState" value={formData.consigneeState} onChange={handleChange} required>
                                <option value="">Select a state</option>
                                {usStates.map(state => (
                                    <option key={state} value={state}>{state}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Consignee Zip</label>
                            <input type="text" name="consigneeZip" value={formData.consigneeZip} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Pickup Date</label>
                            <input type="date" name="pickupDate" value={formData.pickupDate} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Weight</label>
                            <input type="number" name="weight" value={formData.weight} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Pallets</label>
                            <input type="number" name="pallets" value={formData.pallets} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <button type="submit" disabled={isLoading || isBooking}>Submit</button>
                            <button type="button" onClick={handleClear}>Clear</button>
                        </div>
                    </div>

                    <div className="form-column">
                        {quoteResponse && (
                            <div className="quote-summary">
                                <h3>Quote Summary</h3>
                                {Object.keys(quoteResponse).map((key) => (
                                    <div key={key}>
                                        <p><strong>Carrier:</strong> {quoteResponse[key].carrier}</p>
                                        <p><strong>Quote ID:</strong> {quoteResponse[key].quote_id}</p>
                                        <p><strong>Total Cost:</strong> {quoteResponse[key].total_cost}</p>
                                        <button onClick={(e) => handleBookShipment(quoteResponse[key], e)} disabled={isLoading || isBooking}>Book Shipment</button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </form>
            </div>
            {(isLoading || isBooking) && <LoadingDialog />} {/* Show loading dialog */}
            <CustomAlert
                show={alert.show}
                handleClose={handleCloseAlert}
                title={alert.title}
                message={alert.message}
            />
        </div>
    );
};

export default SpotQuotesForm;
