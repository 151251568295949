// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import Layout from './Layout';
import Home from './Home';
import QuotesTable from './QuotesTable';
import SpotQuotesForm from './SpotQuotesForm';
import Contact from './Contact';
import LoginForm from './LoginForm';
import { AuthProvider } from './AuthContext';
import IdleTimeoutWrapper from './IdleTimeoutWrapper';
import ProtectedRoute from './ProtectedRoute';
import BookedShipmentsTable from './BookedShipmentsTable'; // Import the new component

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <IdleTimeoutWrapper timeout={600000}> {/* 10 minutes */}
                    <Routes>
                        <Route path="/login" element={<LoginForm />} />
                        <Route path="/" element={<Navigate to="/login" />} />
                        <Route path="*" element={<Layout> {/* Single instance of Layout at the top level */}
                        <Routes>
                        <Route path="/home" element={<ProtectedRoute component={Home} />} />
                        <Route path="/quotes" element={<ProtectedRoute component={QuotesTable} mainContentClass="no-padding" />} />
                        <Route path="/booked-shipments" element={<ProtectedRoute component={BookedShipmentsTable} />} />
                        <Route path="/spot-quotes" element={<ProtectedRoute component={SpotQuotesForm} mainContentClass="main-content-spot-quotes" />} />
                        <Route path="/contact" element={<ProtectedRoute component={Contact} />} />
                    </Routes>

                        </Layout>} />
                    </Routes>
                </IdleTimeoutWrapper>
            </Router>
        </AuthProvider>
    );
};

export default App;
