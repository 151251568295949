import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import BACKEND_URL from './config'; // Import the BACKEND_URL from the config file

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.post(`${BACKEND_URL}/api/verifyToken`, { token }, { withCredentials: true });
                    if (response.data.valid) {
                        setLoggedIn(true);
                    } else {
                        localStorage.removeItem('token');
                        setLoggedIn(false);
                    }
                } catch (error) {
                    console.error('Token verification failed:', error);
                    localStorage.removeItem('token');
                    setLoggedIn(false);
                }
            } else {
                setLoggedIn(false);  // Ensure loggedIn is false if there's no token
            }
            setLoading(false);  // Set loading to false once the check is done
        };

        verifyToken();
    }, []);  // Dependency array is empty to run this only on component mount

    return (
        <AuthContext.Provider value={{ loggedIn, setLoggedIn, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
