// IdleTimeoutWrapper.js
import React from 'react';
import useIdleTimeout from './useIdleTimeout';

const IdleTimeoutWrapper = ({ timeout, children }) => {
    useIdleTimeout(timeout);
    return <>{children}</>;
};

export default IdleTimeoutWrapper;
