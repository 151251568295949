import React from 'react';
import Sidebar from './Sidebar';
import './styles.css'; // Import your CSS file

const Layout = ({ children, mainContentClass = "" }) => {
    return (
        <div className="app-container">
            <Sidebar />
            <div className={`main-content ${mainContentClass}`}>
                {children}
            </div>
        </div>
    );
};

export default Layout;
