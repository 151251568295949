import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ component: Component, ...props }) => {
    const { loggedIn, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>; // You can replace this with a loading indicator
    }

    return loggedIn ? <Component {...props} /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
