// Home.js
import React, { useContext } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Home = () => {
    const navigate = useNavigate();
    const { loggedIn } = useContext(AuthContext);

    const handleNavigation = (path) => {
        if (loggedIn) {
            navigate(path);
        } else {
            navigate('/login');
        }
    };

    return (
        <Container className="mt-5">
            <Row className="text-center">
                <Col>
                    <h1>Welcome to Freight Ops TMS</h1>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={4}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Manage Quotes</Card.Title>
                            <Card.Text>
                                Create, view, and manage your transportation quotes effortlessly.
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleNavigation('/quotes')}>Learn More</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Spot Quotes</Card.Title>
                            <Card.Text>
                                Get instant spot quotes for your urgent transportation needs.
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleNavigation('/spot-quotes')}>Get Spot Quotes</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Contact Us</Card.Title>
                            <Card.Text>
                                Reach out to us for any queries or support. We are here to help you.
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleNavigation('/contact')}>Contact Support</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
