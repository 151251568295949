import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import BACKEND_URL from './config'; // Import the BACKEND_URL from the config file

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [showModal, setShowModal] = useState(false); // State to manage modal visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${BACKEND_URL}/api/contact`, formData);
            setShowModal(true); // Show the success modal
            // Reset the form data to clear the input fields
            setFormData({
                name: '',
                email: '',
                message: ''
            });
        } catch (error) {
            console.error('There was an error sending the message!', error);
        }
    };

    return (
        <Container className="mt-5">
            <Row>
                <Col>
                    <h1>Contact Us</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter your name" 
                                name="name" 
                                value={formData.name} 
                                onChange={handleChange} 
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className="mt-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter your email" 
                                name="email" 
                                value={formData.email} 
                                onChange={handleChange} 
                            />
                        </Form.Group>

                        <Form.Group controlId="formMessage" className="mt-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                placeholder="Enter your message" 
                                name="message" 
                                value={formData.message} 
                                onChange={handleChange} 
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-3">
                            Submit
                        </Button>
                    </Form>

                    {/* Modal for success message */}
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Message sent successfully
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShowModal(false)}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;
